<template>
  <div class="box">
    <div class="top">
      <div class="logo"><img src="@/assets/img/logo.png" style="width:100%;height:100%" alt=""></div>
      <div class="baogao">我的營養素報告</div>
      <!-- <div class="shop" @click="upto()">營養品商城</div> -->
      <div class="flex">
        <div class="hend"><img src="@/assets/img/hend.png" style="width:100%" alt=""></div>
        <div class="login">登入</div>
      </div>
    </div>
    <div class="card" v-if="phone">
      <div class="lodin">登入</div>
      <div class="modin">
        <div class="modinq">手機號碼：</div>
        <el-input placeholder="請輸入手機號碼" v-model="tell" type="tell"></el-input>
      </div>
      <div class="pass">
        <div class="modinq">密碼：</div>
        <el-input placeholder="請輸入密碼" v-model="pass" show-password></el-input>
      </div>
      <div class="denglu">
        <el-button type="primary" @click="login()">下一步</el-button>
      </div>
    </div>
    <div class="card" v-if="email">
      <div class="lodin">登入</div>
      <div class="modin">
        <div class="modinq">信 箱：</div>
        <el-input placeholder="請輸入信箱" v-model="youxing" type="tell">
          <template slot="append">
            <el-button type="primary" :disabled="disabled" @click="sendcode">{{ disabled ? `${default_time}S` : '發送驗證碼' }}</el-button>
          </template>
        </el-input>
      </div>
      <div class="pass">
        <div class="modinq">驗證碼：</div>
        <el-input placeholder="請輸入驗證碼" v-model="code">

        </el-input>
      </div>
      <div class="denglu">
        <el-button type="primary" @click="email_login()">登入</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { login, sendcode, email_login } from "../../api/login.js"
import { setToken } from "../../utils/auth"
export default {
  data() {
    return {
      phone: true,
      email: false,
      loading: false,
      disabled: false,
      pass: "",
      tell: "",
      default_time: 60,
      time: 60,
      youxing: "",
      code: "",
    }
  },
  methods: {
    upto() {
      window.location.href = "https://www.drnutri.tw/"
    },
    login() {
      this.loading = true
      // console.log(this.tell, this.pass)
      login({ phone: this.tell, password: this.pass })
        .then((res) => {
          // this.$message({
          //   message: "登入成功",
          //   type: "success",
          // })
          // setToken(res.data)
          this.phone = false
          this.email = true
          // setTimeout(() => {
          //   this.$router.replace("/report")
          // }, 1300)
        })
        .catch((err) => {
          //处理返回错误
          // console.log(12, err)
        })
    },
    sendcode() {
      if (this.disabled) return
      if (!this.youxing) {
        return this.$message({
          message: "请输入信箱",
          type: "success",
        })
      }
      this.loading = true

      sendcode({ to: this.youxing, phone: this.tell })
        .then((res) => {
          this.$message({
            message: "發送成功",
            type: "success",
          })
          this.loading = false
          this.disabled = true
          this.default_time = Number(this.time)
          let timer = setInterval(() => {
            this.default_time--
            if (this.default_time == 0) {
              clearInterval(timer)
              this.disabled = false
            }
          }, 1000)

          // setToken(res.data)
          // this.phone = false
          // this.email = true
          // setTimeout(() => {
          //   this.$router.replace("/report")
          // }, 1300)
        })
        .catch((err) => {
          //处理返回错误
          this.loading = false
        })

      // this.$post({
      //   url: "/email/sendEmail",
      //   data: {
      //     to: this.youxing, //邮箱
      //     account: this.tell, //手机号
      //   },
      //   noToken: this.noToken,
      //   success: () => {
      //     this.$message({
      //       message: "发送成功",
      //       type: "success",
      //     })
      //     // this.$toast(this.$t("发送成功"))
      //     this.loading = false
      //     this.disabled = true
      //     this.default_time = Number(this.time)
      //     let timer = setInterval(() => {
      //       this.default_time--
      //       if (this.default_time == 0) {
      //         clearInterval(timer)
      //         this.disabled = false
      //       }
      //     }, 1000)
      //   },
      //   tip: () => {
      //     this.loading = false
      //   },
      // })
    },
    email_login() {
      if (!this.youxing) {
        return this.$message({
          message: "请输入邮箱",
          type: "success",
        })
      }
      if (!this.code) {
        return this.$message({
          message: "请输入验证码",
          type: "success",
        })
      }
      email_login({ email: this.youxing, captcha: this.code, phone: this.tell })
        .then((res) => {
          this.$message({
            message: "登入成功",
            type: "success",
          })
          setToken(res.data)
          setTimeout(() => {
            this.$router.replace("/report")
          }, 1300)
        })
        .catch((err) => {
          //处理返回错误
          // console.log(12, err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
/deep/.el-input-group__append {
  background: #fff !important;
  border: 0px solid #fff !important;
  padding: 0 30px;
  .el-button {
    height: 50px;
  }
}
.flex {
  //width: auto;
  display: flex;
  float: right;
  padding: 0 0;
  margin-right: 4%;
  margin-left: auto;
}
.box {
  width: auto;
  min-width: 1300px;
  height: 1080px;
  background: rgba(255, 255, 255, 0.39);
}
/* 顶部 */
.top {
  width: auto;
  height: 143px;
  min-width: 1300px;
  background: rgb(237, 244, 237);

  display: flex;
  align-items: center;
}
.logo {
  width: 405px;
  height: 143px;
  line-height: 0;
  margin: auto 0;
  padding: 13px 0;
  margin-left: 30px;
  padding: 13px 0;
}
.baogao {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #707070;
  // border-bottom: 3px solid #333333;
  // margin-left: 10%;
  margin: 0 auto;
}
.baogao:hover {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #333333;
  border-bottom: 3px solid #333333;
  margin: 0 auto;
}
.shop {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #707070;
  // border-bottom: 3px solid #707070;
  margin-left: 5%;
}
.shop:hover {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #333333;
  border-bottom: 3px solid #333333;
  margin-left: 5%;
}
.hend {
  width: 30px;
  height: 30px;
  line-height: 0;
}
.login {
  width: 44px;
  height: 30px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 30px;
  color: #333333;
  margin-left: 10px;
}
.card {
  width: 715px;
  height: 522px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 16px 32px rgba(150, 185, 100, 0.16);
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 195px;
  .lodin {
    width: 120px;
    height: 84px;
    font-size: 60px;

    font-weight: bold;
    line-height: 84px;
    color: #333333;
    margin: 0 auto;
    transform: translateY(-50%);
  }
  .modin {
    width: 500px;
    height: 56px;
    background: rgba(255, 255, 255, 0.39);
    border: 1px solid #cecece;
    display: flex;
    margin-top: 106px;
    border-radius: 6px;
    margin: 0 auto;
    .modinq {
      width: 120px;
      height: 56px;
      margin-left: 20px;
      font-size: 16px;

      font-weight: 500;
      line-height: 56px;
      color: #333333;
    }
    /deep/.el-input__inner {
      width: 200px;
      border: none;
      height: 54px;
      line-height: 54px;
    }
  }
  .pass {
    width: 500px;
    height: 56px;
    display: flex;
    background: rgba(255, 255, 255, 0.39);
    border: 1px solid #cecece;
    line-height: 0;
    border-radius: 6px;
    margin: 0 auto;
    margin-top: 50px;
    .modinq {
      width: 120px;
      height: 56px;
      margin-left: 20px;
      font-size: 16px;

      font-weight: 500;
      line-height: 56px;
      color: #333333;
    }
    /deep/.el-input__inner {
      width: 200px;
      border: none;
      height: 54px;
      line-height: 54px;
    }
  }
  .denglu {
    width: 500px;
    margin: 0 auto;
    margin-top: 62px;
    .el-button {
      width: 500px;
      height: 64px;
      background: rgb(51, 51, 51);
      border-color: rgb(51, 51, 51);
      border-radius: 6px;
    }
    .el-button--primary:focus,
    .el-button--primary:hover {
      border-color: rgb(51, 51, 51);
    }
  }
}
</style>
